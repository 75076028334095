<template>
  <div class="item">
    <TitleHeader msg="转账"></TitleHeader>
    <el-row class="content">
      <el-col class="wraper">
        <el-col :span="3" class="text-right">转账金额：</el-col>
        <el-col :span="4"
          ><el-input
            v-model="input"
            placeholder="请输入转账金额"
            oninput="value=value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')"
            show-word-limit
          ></el-input
        ></el-col>
      </el-col>

      <el-col class="wraper">
        <el-col :span="3" class="text-right">选择门店：</el-col>
        <el-col :span="4">
          <el-select v-model="value" placeholder="请选择" @change="changeFun">
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.enterpriseName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-col>

      <el-col class="wraper">
        <el-col :span="3" class="text-right">备注：</el-col>
        <el-col :span="4"
          ><el-input v-model="info" placeholder="请输入备注信息"></el-input
        ></el-col>
      </el-col>
      <el-col class="wraper">
        <el-col :span="3" class="text-right">支付密码：</el-col>
        <el-col :span="4"
          ><el-input
            v-model="paypas"
            ref="paypas"
            type="password"
            placeholder="请输入6位支付密码"
            onkeyup="value=value.replace(/[^\d]/g,'')"
            :maxlength="6"
          ></el-input
        ></el-col>
      </el-col>
      <el-col class="wraper">
        <el-col :span="3"></el-col>
        <el-col :span="8"
          ><el-button type="danger" size="mini" @click="submitFun"
            >确定转账</el-button
          ></el-col
        >
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { GetMemberStore, TransferAccounts, MemberInfo } from "api/member.js";
const TitleHeader = () => import("components/common/TitleHeader.vue");
export default {
  name: "TransferAccount",
  data() {
    return {
      isPayPas: "", //是否设置支付密码 1设置，0未设置
      memberStoreId: "", //当前门店Id
      info: "", //描述信息
      //转账金额初始值
      input: "",
      //门店默认值
      value: "",
      paypas: "", //支付密码
      options: "",
    };
  },
  methods: {
    changeFun(id) {
      this.value = id;
    },
    submitFun() {
      // 先判断有无设置支付密码
      MemberInfo().then((res) => {
        this.isPayPas = res.data.data.isSetPayPassword;
        if (this.isPayPas === 0) {
           this.$message({
              message: "请先设置支付密码",
              type: "warning",
            });
          // 跳转设置支付密码
          this.$router.push({ name: "Paypass",query:{typeUp:"TransferAccounts"} });
        } else {
          if (this.input == "") {
            this.$message({
              message: "请输入转账金额",
              type: "warning",
            });
            return false;
          } else {
            if (this.value == "") {
              this.$message({
                message: "请选择门店",
                type: "warning",
              });
              return false;
            } else {
              if (this.info == "") {
                this.$message({
                  message: "请输入备注",
                  type: "warning",
                });
                return false;
              } else {
                if (this.paypas == "") {
                  this.$message({
                    message: "请输入支付密码",
                    type: "warning",
                  });
                  return false;
                }
              }
            }
          }

          let datas = {
            intoMemberStoreId: this.value, //到账会员id
            remark: this.info, // 备注
            money: this.input, // 金额
            payPassword: this.$md5(this.paypas), // 支付密码
          };
          TransferAccounts(datas).then((data) => {
            if (data.data.code == 200) {
              this.$message({
                message: data.data.msg,
                type: "success",
              });
              this.dialogFormVisible = false;
              this.$router.push({ path: "/my" });
            } else {
              this.$message.error(data.data.msg);
              this.paypas = "";
              this.$nextTick(() => {
                this.$refs.paypas.focus();
              }, 100);
              return;
            }
          });
        }
      });
    },
  },
  created() {
    //  获取子门店
    GetMemberStore({ type: 1 }).then((res) => {
      this.options = res.data.data;
      if (this.options.length < 1) {
        this.$message({
          message: "无子门店不能转账",
          type: "warning",
        });
        this.$router.push({ path: "/my" });
      }
    });
  },
  components: {
    TitleHeader,
  },
};
</script>
<style lang="less" scoped>
@import "~style/index.less";
.item {
  color: #666;
  .fonts();
  .content {
    padding-top: 16px;
  }

  .wraper {
    display: flex;
    align-items: center;
    .el-col {
      padding-top: 15px;
      padding-bottom: 15px;
    }
    .el-col:nth-child(2) {
      padding-left: 10px;
    }
  }
  /deep/.el-button--danger {
    color: #000;
    background-color: #FFD303;
    border-color: #FFD303;
    width: 136px;
    height: 36px;
    color: #000;
    opacity: 0.8;
  }
  /deep/.el-input__inner {
    border-radius: 0px;
    width: 190px;
    height: 36px;
  }
}
</style>
